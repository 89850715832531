import React, {SyntheticEvent} from 'react';

import {CircleArrowIcon} from '@components/icons';
import {styled, Colors} from '@styles';

const CarouselButtonContainer = styled.button.attrs(() => ({
  tabIndex: -1,
}))<ButtonProps>`
  background: ${Colors.Transparent};
  padding: 0;
  border: none;
  align-self: ${({arrowDirection}) =>
    arrowDirection === 'next' ? 'flex-start' : 'flex-end'};
  cursor: pointer;
`;

interface ButtonProps {
  onClick: (event: SyntheticEvent<Element, Event>) => void;
  arrowDirection: 'next' | 'prev';
}

export default function CarouselButton({
  onClick,
  arrowDirection,
}: ButtonProps): JSX.Element {
  return (
    <CarouselButtonContainer arrowDirection={arrowDirection} onClick={onClick}>
      <CircleArrowIcon
        rotate={arrowDirection === 'next' ? 0 : 180}
        arrowFill={Colors.White}
        backgroundFill={Colors.Blue}
      />
    </CarouselButtonContainer>
  );
}
