import React from 'react';
import loadable from '@loadable/component';
import {LatestPostsData} from './useLatestPosts';

export type LatestPostsRenderProps = LatestPostsData;

export interface LatestPostsDataLoaderProps {
  children: (props: LatestPostsRenderProps) => JSX.Element;
}

const LatestPostsLoader = loadable.lib(() =>
  import(
    /* webpackChunkName: "latest-posts" */
    '@util/useLatestPosts'
  ),
);

interface LatestPostsDataRendererProps {
  children: LatestPostsDataLoaderProps['children'];
  useLatestPosts: () => LatestPostsData;
}

const LatestPostsDataRenderer = ({
  children,
  useLatestPosts,
}: LatestPostsDataRendererProps): JSX.Element => {
  const latestPosts = useLatestPosts();
  return children(latestPosts);
};

export default function LatestPostsDataLoader({
  children,
}: LatestPostsDataLoaderProps): JSX.Element {
  return (
    <LatestPostsLoader>
      {({default: useLatestPosts}) => (
        <LatestPostsDataRenderer useLatestPosts={useLatestPosts}>
          {children}
        </LatestPostsDataRenderer>
      )}
    </LatestPostsLoader>
  );
}
