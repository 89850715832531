import React, {useContext} from 'react';
import {Interpolation} from 'react-spring';

export interface CarouselContextValue {
  activeStateProgress: (index: number) => Interpolation<number, number>;
  goto: (index: number) => void;
}

const CarouselContext = React.createContext<CarouselContextValue>({
  activeStateProgress: () => {
    throw new Error('A context value must be provided!');
  },
  goto: () => {
    throw new Error('A context value must be provided!');
  },
});

export const useCarouselContext = (): CarouselContextValue => {
  return useContext(CarouselContext);
};

export default CarouselContext;
