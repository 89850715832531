import React from 'react';
import {animated, to, FrameValue} from 'react-spring';
import {getSrc, getImage, GatsbyImageProps} from 'gatsby-plugin-image';
import useSize from '@hzdg/use-size';
import {Headline, Paragraph} from '@components/typography';
import Image from '@components/Image';
import {Link} from '@components/Link';
import {useCarouselContext} from './CarouselContext';
import {styled, Colors, Fonts} from '@styles';
import formatLatestPostDate from '@util/formatLatestPostDate';
import generateByline from '../../util/generateByline';

const Eyebrow = styled(Paragraph).withConfig({
  componentId: 'carouselCardEybrow'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
  text-transform: uppercase;
  color: ${Colors.Blue};
  font-size: ${16 / 16}em;
  letter-spacing: 0.5;
  margin: ${12 / 25}em 0;
`;

const CarouselCardHeadline = styled(Headline).withConfig({
  componentId: 'carouselCardHeadline'
})`
  font-size: ${30 / 16}em;

  .wide & {
    font-size: ${30 / 16}em;
  }
`;

const ACTIVE_SCALE = Math.round((680 / 497) * 100) / 100;

const CardContainer = styled(animated.div).withConfig({
  componentId: 'carouselCardContainer'
})`
  width: calc(${Math.round(100 / ACTIVE_SCALE)}vw - 50px);
  max-width: 497px;
  min-width: 290px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0 30px;
`;

const BylineContainer = styled(animated.div).withConfig({
  componentId: 'carouselCardBylineContainer'
})``;

const ColumnContainer = styled.div.withConfig({
  componentId: 'carouselCardColumnContainer'
})`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  .wide & {
    padding: 30px;
  }
`;

const DateAutorCopy = styled.span.withConfig({
  componentId: 'carouselCardDateAutorCopy'
})`
  ${Fonts.ProximaNova};
  color: ${Colors.CharcoalLight};
  font-size: ${14 / 16}em;
`;

const AnimatedImage = animated(Image);

const AnimatedParagraph = styled(animated(Paragraph)).withConfig({
  componentId: 'carouselCardParagraph'
})`
  font-size: ${17 / 16}em;
`;

export interface CarouselItemProps {
  category?: {name: string; slug: string};
  title: string;
  excerpt: string;
  publicationDate: string;
  author?: {name: string; slug: string};
  image?: {childImageSharp: GatsbyImageProps};
  index: number;
  url: string;
  className?: string;
  featured?: boolean;
  tags?: {name: string}[];
  shouldInterpolate?: boolean;
  preserveTitleCase?: boolean;
}

export default function CarouselCard({
  category,
  excerpt,
  title,
  publicationDate,
  url,
  author,
  authors = [],
  image,
  index,
  shouldInterpolate = true,
  preserveTitleCase = false,
  className,
}: CarouselItemProps): JSX.Element {
  const [
    {
      contentBoxSize: {blockSize: pSize},
    },
    pRef,
  ] = useSize();
  const {activeStateProgress} = useCarouselContext();
  let paragraphOpacity: number | FrameValue<number> = 1;
  let cardTransform: string | FrameValue<string> = 'none';
  let imageTransform: string | FrameValue<string> = 'none';
  let bylineTransform: string | FrameValue<string> = 'none';

  if (shouldInterpolate) {
    const progress = activeStateProgress(index);
    paragraphOpacity = progress.to({range: [0.4, 0.5, 0.6], output: [0, 1, 0]});
    const cardX = progress.to({range: [0, 0.5, 1], output: [0, -3, 136]});
    const cardY = progress.to({range: [0, 0.5, 1], output: [0, 50, 0]});
    cardTransform = to([cardX, cardY], (x, y) => `translate(${x}px, ${y}px)`);
    imageTransform = progress
      .to({range: [0, 0.5, 1], output: [1, ACTIVE_SCALE, 1]})
      .to(v => `scale(${v})`);
    bylineTransform = progress
      .to<number>({range: [0, 0.5, 1], output: [-pSize - 10, 0, -pSize - 10]})
      .to(v => `translateY(${v}px)`);
  }

  const titleLinkStyle: React.CSSProperties = {};
  if (preserveTitleCase) {
    titleLinkStyle.textTransform = 'none';
  }

  const AuthorLink = ({href, children}) => (
    <Link href={href}>
      <DateAutorCopy style={{marginLeft: '10px'}}>{children}</DateAutorCopy>
    </Link>
  );

  return (
    <CardContainer className={className} style={{transform: cardTransform}}>
      {image && (
        <AnimatedImage
          fadeIn={false}
          childImageSharp={image.childImageSharp}
          style={{
            width: '100%',
            transformOrigin: 'left bottom',
            transform: imageTransform,
          }}
        />
      )}
      <ColumnContainer>
        {category && category.name && (
          <Link href={`/newsroom/${category.slug}/`}>
            <Eyebrow>{category.name}</Eyebrow>
          </Link>
        )}
        {title && (
          <Link href={url} style={titleLinkStyle}>
            <CarouselCardHeadline as={`h3`}>{title}</CarouselCardHeadline>
          </Link>
        )}
        <AnimatedParagraph ref={pRef} style={{opacity: paragraphOpacity}}>
          {excerpt}
        </AnimatedParagraph>
        <BylineContainer style={{transform: bylineTransform}}>
          <DateAutorCopy style={{marginRight: '10px', position:'relative'}}>
            {formatLatestPostDate(publicationDate)}
          </DateAutorCopy>
          &middot;
          <DateAutorCopy style={{marginLeft: '10px', position:'relative'}}>
            {authors && generateByline(authors)}
          </DateAutorCopy>
        </BylineContainer>
      </ColumnContainer>
    </CardContainer>
  );
}
