const CLICK = 'click';

/**
 * A simple utility for hijacking the next click event.
 *
 * This is useful in scenarios where elements that register
 * clicks are under gesture—if the gesture is a drag (mouse or touch),
 * a 'click' event can register after the drag finishes if the 'mouseup'
 * or 'touchend' occurs over an element with a click handler.
 */
export default class ClickHack {
  constructor(private root?: Window | Node) {
    if (!root && typeof window !== 'undefined') {
      this.root = window;
    }
  }

  private _nextClickPrevented = false;

  get nextClickPrevented(): boolean {
    return this._nextClickPrevented;
  }

  private clickHandler = (event: Event): void => {
    event.preventDefault();
    event.stopPropagation();
    this.cancel();
  };

  /**
   * Prevent the next click from propagating.
   *
   * Note: you can cancel this with `clickHack.cancel()`.
   */
  preventNextClick(): void {
    if (typeof this.root === 'undefined') return;
    if (this._nextClickPrevented) return;
    this._nextClickPrevented = true;
    this.root.addEventListener(CLICK, this.clickHandler, true);
  }

  /**
   * Cancel prevention of the next click.
   */
  cancel(): void {
    this._nextClickPrevented = false;
    if (typeof this.root === 'undefined') return;
    this.root.removeEventListener(CLICK, this.clickHandler, true);
  }
}
