import React, {useMemo, useCallback, useState, useEffect, useRef} from 'react';
import {animated, useSpring} from 'react-spring';
import {useGesture} from 'react-use-gesture';
import {
  UserHandlersPartial,
  UseGestureConfig,
} from 'react-use-gesture/dist/types';
import useSize, {Size} from '@hzdg/use-size';
import useRefCallback from '@hzdg/use-ref-callback';
import {FocusScope, FocusManager} from '@hzdg/focus-scope';
import {styled} from '@styles';
import CarouselButton from './CarouselButton';
import CarouselContext, {CarouselContextValue} from './CarouselContext';
import ClickHack from './ClickHack';

const NEXT = 'NEXT';
const PREVIOUS = 'PREVIOUS';
const FIRST = 'FIRST';
const LAST = 'LAST';

const WHEEL_THRESHOLD = 3;

type PaginationAction =
  | typeof NEXT
  | typeof PREVIOUS
  | typeof FIRST
  | typeof LAST
  | number;

const clamp = (value: number, min: number, max: number): number =>
  Math.max(min, Math.min(max, value));

const CarouselContainer = React.memo(styled.div.withConfig({
  componentId: 'carouselContainer'
})<{size?: Size}>`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: ${({size}) => (size ? `${Math.ceil(size.height * 1.3)}px` : '500px')};
`);
CarouselContainer.displayName = 'CarouselContainer';

const CarouselInnerContainer = styled(animated.div).withConfig({
  componentId: 'carouselInnerContainer'
})<{size?: Size}>`
  display: flex;
  position: absolute;
  outline: none;
  top: ${({size}) =>
    size
      ? `${Math.ceil((Math.ceil(size.height * 1.3) - size.height) / 2)}px`
      : '75px'};
  left: 0;
  min-width: 100%;
`;

const CarouselButtonsContainer = styled.div.attrs(() => ({
  ['aria-hidden']: true,
})).withConfig({
  componentId: 'carouselButtonsContainer'
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  width: 0px;
  height: 74px;
  top: 50%;
  right: 25px;
  margin-top: -37px;
  outline: 0;
`;

const getChildFocusScopeId = (index: number): string =>
  `carousel-child-${index}`;

const getChildIndexFromFocusScopeId = (id: string | null): number =>
  parseInt(id?.split('-').pop() ?? '-1', 10);

const ChildWithFocusScope = (props: {
  children: React.ReactNode;
  index: number;
  goto: (index: number) => void;
}): JSX.Element => {
  const {index, goto, children} = props;
  const id = getChildFocusScopeId(index);
  const handleFocus = useCallback(() => goto(index), [goto, index]);
  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    event.key === 'Tab' && event.preventDefault();
  }, []);
  return (
    <FocusScope id={id} onFocus={handleFocus} onKeyPress={handleKeyPress}>
      {children}
    </FocusScope>
  );
};

export default function Carousel({
  children,
}: React.PropsWithChildren<{
  activeSlide?: number;
}>): JSX.Element {
  /** Use a callback ref to make sure our hooks know when we're attached to a DOM node. */
  const [ref, setRef] = useRefCallback<HTMLDivElement>(null);
  /** The size of the carousel. */
  const size = useSize(ref);
  /** The number of slides in the carousel. */
  const totalSlides = React.Children.count(children);
  /** How wide a slide is. */
  const slideWidth = Math.ceil(size.width / totalSlides);
  /** Keep track of the active slide. */
  const activeSlide = useRef(0);
  /** Whether or not there are more slides after the active slide. */
  const [hasNext, setHasNext] = useState(activeSlide.current < totalSlides - 1);
  /** Whether or not there are more slides before the active slide. */
  const [hasPrev, setHasPrev] = useState(activeSlide.current > 0);
  /** Intercept clicks and prevent them during gesturing. */
  const clickHack = useMemo(() => new ClickHack(), []);
  /** Keep track of when wheel events are blocked from gesturing. */
  const wheelBlocked = useRef(false);

  /** Transform the carousel position based on active slide and gesture states. */
  const [transform, setTransform] = useSpring(() => ({x: 0}));

  const activeStateProgress = useCallback(
    (index: number) => {
      const activeX = index * -slideWidth;
      const activeMin = activeX - slideWidth;
      const activeMax = activeX + slideWidth;
      return transform.x.to(x => {
        if (x === activeX) return 0.5;
        if (x <= activeMin) return 0;
        if (x >= activeMax) return 1;
        return (x - activeMin) / (activeMax - activeMin);
      });
    },
    [transform, slideWidth],
  );

  /** `goto` will change the active slide based on the intent of a gesture. */
  const goto = useCallback(
    (action: PaginationAction): void => {
      // Calculate the next active slide based on the page action.
      switch (action) {
        case NEXT:
          activeSlide.current += 1;
          break;
        case PREVIOUS:
          activeSlide.current -= 1;
          break;
        case FIRST:
          activeSlide.current = 0;
          break;
        case LAST:
          activeSlide.current = totalSlides - 1;
          break;
        default:
          activeSlide.current = action;
          break;
      }
      // Clamp between 0 (inclusive) and the total number of slides.
      activeSlide.current = clamp(activeSlide.current, 0, totalSlides - 1);
      // Update the carousel position to show the next active slide.
      setTransform({x: activeSlide.current * -slideWidth});
      setHasPrev(activeSlide.current !== 0);
      setHasNext(activeSlide.current !== totalSlides - 1);
    },
    [activeSlide, setTransform, slideWidth, totalSlides],
  );

  // Provide some context for the carousel slides.
  const contextValue = useMemo<CarouselContextValue>(
    () => ({activeStateProgress, goto}),
    [activeStateProgress, goto],
  );

  const gestureEventHandlers = useMemo<UserHandlersPartial>(
    () => ({
      onMouseDown: event => {
        // Prevent mousedown events so that native drag doesn't happen.
        event.preventDefault();
      },
      onDrag({down, swipe, movement: [mx]}) {
        if (down) {
          event?.preventDefault();
          // Prevent clicks from registering,
          // since we are now officially dragging.
          // Note: This is the _opposite_ of what `filterTaps` does,
          // which is to prevent _dragging_ when a user wants to click.
          clickHack.preventNextClick();
          setTransform({x: mx, immediate: true});
        } else if (swipe && swipe[0]) {
          goto(swipe[0] === 1 ? PREVIOUS : NEXT);
        } else {
          goto(Math.round(Math.abs(mx / slideWidth)));
        }
      },
      onWheel({event, movement: [mx], delta: [dx, dy]}) {
        if (wheelBlocked.current) return;
        wheelBlocked.current = Math.abs(dy) - Math.abs(dx) > WHEEL_THRESHOLD;
        if (wheelBlocked.current) {
          goto(Math.round(Math.abs(mx / slideWidth)));
          return;
        }
        event?.preventDefault();
        setTransform({x: -mx, immediate: true});
      },
      onWheelEnd({movement: [mx]}) {
        if (wheelBlocked.current) {
          wheelBlocked.current = false;
        } else {
          goto(Math.round(Math.abs(mx / slideWidth)));
        }
      },
    }),
    [clickHack, goto, setTransform, slideWidth],
  );

  const gestureConfig = useMemo<UseGestureConfig>(
    () => ({
      domTarget: ref,
      eventOptions: {passive: false},
      drag: {
        axis: 'x',
        bounds: {left: (totalSlides - 1) * -slideWidth, right: 0},
        filterTaps: true,
        initial: () => [transform.x.get(), 0],
        rubberband: true,
      },
      wheel: {
        lockDirection: true,
        bounds: {left: 0, right: (totalSlides - 1) * slideWidth},
        initial: () => [-transform.x.get(), 0],
        rubberband: true,
      },
    }),
    [ref, slideWidth, totalSlides, transform],
  );

  const bindGestureHandlers = useGesture(gestureEventHandlers, gestureConfig);
  useEffect(() => void bindGestureHandlers(), [bindGestureHandlers]);

  const handleNext = useCallback(() => goto(NEXT), [goto]);
  const handlePrev = useCallback(() => goto(PREVIOUS), [goto]);
  const handleKeyPress = useCallback(
    (event: KeyboardEvent, focusManager: FocusManager) => {
      switch (event.key) {
        case 'Tab': {
          if (event.shiftKey) {
            if (focusManager.focusPrevious({preventScroll: true})) {
              if (!event.defaultPrevented) {
                event.preventDefault();
              }
            }
          } else {
            if (focusManager.focusNext({preventScroll: true})) {
              if (!event.defaultPrevented) {
                event.preventDefault();
              }
            }
          }
          break;
        }
        case 'ArrowRight': {
          // Treat right arrow presses as moving to the next card.
          event.preventDefault();
          const currentElement = focusManager.getFocusedElement(true);
          if (currentElement) {
            const childManager = focusManager.findClosestManager(
              currentElement,
            );
            if (childManager) {
              const index = getChildIndexFromFocusScopeId(childManager.id);
              if (index >= 0) {
                const nextChildManager = focusManager.findManagerById(
                  getChildFocusScopeId(index + 1),
                );
                if (nextChildManager) {
                  nextChildManager.focusFirst({preventScroll: true});
                }
              }
            }
          }
          break;
        }
        case 'ArrowLeft': {
          // Treat left arrow presses as moving to the previous card.
          event.preventDefault();
          const currentElement = focusManager.getFocusedElement(true);
          if (currentElement) {
            const childManager = focusManager.findClosestManager(
              currentElement,
            );
            if (childManager) {
              const index = getChildIndexFromFocusScopeId(childManager.id);
              if (index > 0) {
                const previousChildManager = focusManager.findManagerById(
                  getChildFocusScopeId(index - 1),
                );
                if (previousChildManager) {
                  previousChildManager.focusFirst({preventScroll: true});
                }
              }
            }
          }
          break;
        }
      }
    },
    [],
  );

  return (
    <CarouselContext.Provider value={contextValue}>
      <CarouselContainer size={size}>
        <FocusScope
          as={CarouselInnerContainer}
          onKeyPress={handleKeyPress}
          ref={setRef}
          size={size}
          style={{transform: transform.x.to(x => `translateX(${x}px)`)}}
        >
          {React.Children.map(children, (child, index) => (
            <ChildWithFocusScope
              key={getChildFocusScopeId(index)}
              index={index}
              goto={goto}
            >
              {child}
            </ChildWithFocusScope>
          ))}
        </FocusScope>
        <CarouselButtonsContainer>
          {hasNext && (
            <CarouselButton onClick={handleNext} arrowDirection="next" />
          )}
          {hasPrev && (
            <CarouselButton onClick={handlePrev} arrowDirection="prev" />
          )}
        </CarouselButtonsContainer>
      </CarouselContainer>
    </CarouselContext.Provider>
  );
}
